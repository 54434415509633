
  @media (max-width: 920px) {
   
      html,
      body,
      #root,
      #root > div {
      
        overflow-x: hidden !important;
      }

    }
    @media only screen and (min-device-width: 0px) and (max-device-width: 386px)
    {
       .firstbox{
            display: block !important;
            padding: 1rem !important;
        }
        .headerText{
            margin-top: 0 !important;
            font-size: 61px !important;
        }
        .headerTextsecond{
             padding-right: 2rem !important; 
        }
        .secondbox{
            padding:0rem !important
            
        }
        .imgaeStorage{
            width:100% !important;
            object-fit: contain !important;
        }
        .siftedStyle{
            padding-left: 1rem !important;
            margin-left: 1rem !important;
        }
        .imgaeStorageparnt{
            flex-direction: column !important;
            align-items: center !important;
            margin-left: 1rem !important ;
            margin-right: 1rem !important;

          }
          .goTosifted{
            margin-left: 0 !important;
            text-align: left !important;
          }
                  .none {
                      display: none !important;
                  }
        
                  .marginSifted {
                      margin-right: 0 !important;
                  }
                  }
    @media only screen and (min-device-width:386px) and (max-device-width: 600px)
    
    {
        .siftedStyle{
            padding-left: 1rem !important;
            margin-left: 0.6rem !important;
        }
        .marginSifted {
            margin-right: -20px !important;
        }
       .buttonstyle{
        height: 30px !important;
        margin-top:  0 !important;
       }
       .text{
        font-size: 61px !important;
       }
        .firstbox{
            display: block !important;
            padding: 1rem !important;
        }
        .headerText{
            margin-top: 0 !important;
            font-size: 66px !important;
        }
        .headerTextsecond{
             padding-right: 2rem !important; 
        }
        .secondbox{
            padding:0rem !important
            
        }
        .imgaeStorage{
            width:100% !important;
            object-fit: contain !important;
        }
        .imgaeStorageparnt{
            flex-direction: column !important;
            align-items: center !important;
            margin-left: 1rem !important ;
            margin-right: 1rem !important;
          }
          .goTosifted{
            margin-left: 0 !important;
            text-align: left !important;
          }
          .none{
display: none !important;
          }
      
    }
    @media only screen and (min-device-width:700px) and (max-device-width: 830px)
    {
      .imgaeStorageparnt{
        flex-direction: column !important;
        align-items: center !important;
      }
      .goTosifted{
        margin-left: 0 !important;
        text-align: left !important;
      }
    }
   

